nav {
  header.header {
    .nav-toggle {
      width: 35px;
      cursor: pointer;
      gap: 5px;
      &:hover {
        .bar {
          background: rgb(200, 200, 200);
        }
      }
      .bar {
        transition: 100ms ease all;
        height: 5px;
        background: white;
        margin-bottom: 5px;
        border-radius: 5px;
      }
    }
  }
}
