@import "./vars/vars";
@import "./globals/globals";
@import "loaders";

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

:root {
  margin: 0;
  padding: 0;
}
body {
  // Basically removing the body element, and making the .container replace it
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.container {
  // Acts as a replacement for the <body> element
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
}

@import "./header/header";

@import "./errors/error_page.scss";

@import "../pages/connector";
