@mixin f-c-fs-c {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.page.home {
  @include f-c-fs-c;
  button {
    padding: 5px 20px;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: 100ms ease all;
  }
  button.delete {
    background: red;
    color: white;
    &:hover {
      background: darkred;
    }
  }
  button.add {
    background: green;
    color: white;
    &:hover {
      background: darkgreen;
    }
  }
  button.edit {
    background: darkmagenta;
    color: white;
    &:hover {
      background: purple;
    }
  }
  .wrapper {
    @include f-c-fs-c;
    gap: 10px;
    form {
      @include f-c-fs-c;
      gap: 10px;
      margin-top: 10px;
      &.login {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        button {
          background: rgb(40,40,40);
          &:hover {
            background: rgb(50,50,50);
          }
        }
      }
      .tip {
        @include f-c-fs-c;
        text-align: center;
        width: 100%;
        $rgb: 45;
        background: rgb($rgb,$rgb,$rgb);
        padding: 5px 5px;
        span {
          strong {
            padding: 0 5px;
            $rgb: 75;
            background: rgb($rgb,$rgb,$rgb);
            border-radius: 5px;
          }
        }
      }
      .text {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        input {
          font-size: 16px;
          background: rgb(40,40,40);
          border-radius: 5px;
          outline: none;
          border: none;
          padding: 10px 20px;
          color: rgb(200,200,200);
          &:focus,&:target,&:active {
            outline: 1px solid rgb(150,150,150);
          }
        }
      }
    }
    .data-operations {
      margin-top: 10px;
    }
    .data-display {
      @include f-c-fs-c;
      width: 100%;
      margin-bottom: 100px;
      position: relative;
      margin-top: 10px;
      &::before {
        content: "";
        background: rgb(65,65,65);
        width: 100%;
        height: 1px;
        top: 0;
        position: absolute;
      }
      ul {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        list-style: none;
        gap: 10px;
        margin: 0;
        padding: 10px;
        li {
          word-break: break-all;
          @include f-c-fs-c;
          max-width: 400px;
          min-width: 300px;
          gap: 10px;
          padding-bottom: 10px;
          padding: 20px;
          background: rgb(35,35,35);
          border-radius: 10px;
          .id {
            padding: 5px 10px;
            background: rgb(20,20,20);
            border-radius: 5px;
          }
          h2 {
            margin: 0;
            padding: 0;
          }
          // .actions {
            
          // }
        }
      }
    }
  }
}
